.main-banner-title {
  height: 150px;
  background-image: url("/assets/images/back_wave_2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: soft-light;
  background-color: var(--ologoYellow);
  display: flex;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  h1 {
    color: var(--textwhite);
    margin: auto auto;
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 5px;
    text-align: center;

    @include media-breakpoint-down(md) { font-size: 22px; }
    @include media-breakpoint-down(sm) { font-size: 18px; }
  }
}

#master-overlay {
  background: #5d5d5d;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: .80;
  display: none;
}


// region MACRO

@mixin boxShadow ($first, $second, $third, $color) {
  -moz-box-shadow: $first $second $third $color;
  -webkit-box-shadow: $first $second $third $color;
  box-shadow: $first $second $third $color;
}

@mixin boxShadow5 ($first, $second, $third, $four, $color) {
  -moz-box-shadow: $first $second $third $four $color;
  -webkit-box-shadow: $first $second $third $four $color;
  box-shadow: $first $second $third $four $color;
}

@mixin pngDropShadow ($first, $second, $third, $color) {
  -webkit-filter: drop-shadow($first $second $third $color);
  filter: drop-shadow($first $second $third $color);
}

// endregion



// region SweetAlert2

.swal2-modal {
  border-radius: 20px !important;
}

.swal2-ok-button {
  border: 2px solid var(--ctrlBorder);
  background: var(--ologoYellow);
  color: var(--white);
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 6px 36px;
}

// endregion