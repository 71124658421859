@import "base";
@import "common";

.home-page {

  .main-banner-group {
    display: flex;

    .main-banner-container {
      height: 203px;
      overflow: hidden;
      width: 100%;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      img {
        width: 100%;
        opacity: 0.4;
      }
    }

    .main-banner-logo {
      position:  absolute;
      left: 0;
      margin-top: 46px;
      width: 100%;

      img {
        width: 200px;
        margin: 0 auto;
        display: flex;
      }
    }
  }

  h1 {
    width: 50%;
    font-size: 25px;
    color: var(--ologoRed);
    text-align: center;
    margin: 27px auto 0 auto;
    font-weight: normal;


    @include media-breakpoint-down(lg) { width: 67%; }
    @include media-breakpoint-down(md) { width: 75%; }
    @include media-breakpoint-down(sm) { width: 98%; }

  }

  .divider {
    margin-top: 30px;
  }

  .three-steps {
    margin-top: 75px;
    margin-bottom: 75px;

    a {
      text-decoration: none;

      &:hover {
        img {
          @include pngDropShadow(5px, 5px, 5px, var(--ologoYellow));
        }
      }

      img {
        max-width: 70%;
        margin: auto auto;
        display: flex;
        @include pngDropShadow(5px, 5px, 5px, var(--shadowImg));

        @include media-breakpoint-down(lg) { max-width: 270px; }
        @include media-breakpoint-down(md) { max-width: 240px; }
        @include media-breakpoint-down(sm) { max-width: 200px; }
      }

      h2 {
        text-align: center;
        color: var(--ologoRed);
        font-size: 25px;
        margin: 14px 0 10px 0;
      }

      article {
        width: 80%;
        color: var(--ologoYellow);
        margin: 0 auto;
      }
    }

    .col-step-1 {
      article {
        text-align: left;

        @include media-breakpoint-down(lg) { text-align: center; }
      }
    }

    .col-step-2 {
      @include media-breakpoint-down(lg) { margin-top: 37px; }

      article { text-align: center; }
    }

    .col-step-3 {
      @include media-breakpoint-down(lg) { margin-top: 37px; }

      article {
        text-align: right;

        @include media-breakpoint-down(lg) { text-align: center; }
      }
    }
  }

  .sections {
    margin-top: 30px;

    .section-title {
      text-align: center;

      span {
        padding: 0 14px;
        color: var(--ologoRed);
      }

      i {
        &:first-child {
          color: var(--iconbase);
          font-size: 19px;
        }
        &:last-child {
          color: var(--ologoYellow);
          font-size: 21px;
        }
      }
    }

    .section-body {

      article {
        text-align: justify;
        padding: 12px 32px;
        color: var(--textprimary);

        ul {
          text-align: left;
        }
      }
    }

    .demo {
      margin-top: 28px;
    }

    .characteristics {

      @include media-breakpoint-down(md) { margin-top: 28px; }
    }
  }
}